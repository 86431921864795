<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        style="display:flex"
      >
        <logo class="d-none d-lg-block" />

      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            fluid
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 text-center"
          >
            {{ $t('reset_password') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('reset_password_description') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              @submit.prevent="submit"
            >

              <!-- password -->
              <b-form-group
                :label="$t('new_password')"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('password')"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('confirm_password')"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="password_confirmation"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                type="submit"
                variant="primary"
                block
                class="mt-2"
              >
                <!-- :disabled="!form.recaptcha" -->
                <span v-if="!pending">{{ $t('set_new_password') }}</span>
                <b-spinner
                  v-else
                  small
                  type="grow"
                />
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-button
              type="submit"
              variant="secondary"
              block
              :to="{name:'login'}"
            >
              {{ $t('back_to_login') }}
            </b-button>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg, BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import logo from '@/views/components/logo.vue'
import store from '@/store/index'
import AuthApis from '@/services/apis/auth'

const auth = new AuthApis()
export default {
  components: {
    logo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    BSpinner,
  },
  data() {
    return {
      email: this.$route.query.email,
      token: this.$route.query.token,
      password_confirmation: '',
      password: '',
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      required,
      password1FieldType: 'password',
      password2FieldType: 'password',
      pending: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async submit() {
      const success = await this.$refs.simpleRules.validate()
      if (success) {
        this.pending = true
        const parameter = {
          token: this.token,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        }
        auth.resetPassword(parameter).then(res => {
          this.$swal({
            title: res.data.message,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false,
          })
          if (res.status === 419) {
            this.$router.push(`/${this.$i18n.locale}/forgot-password`)
          } else if (res.status === 201) {
            this.$router.push(`/${this.$i18n.locale}/login`)
          }
        }).finally(() => {
          this.pending = false
        })
      }
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
